/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Cookies from 'js.cookie';
window.Cookies = Cookies;
import 'gdpr/cookie_consent';

import FastClick from 'fastclick';

import Rails from '@rails/ujs';
Rails.start();

import I18n from '../js/utils/i18n';
I18n.attachToWindow();

import widgets from 'widjet';
import {merge} from 'widjet-utils';
import 'bootstrap/dist/js/bootstrap.js';
import 'widjet-validation';
import VALIDATION_OPTIONS from '../js/widgets/validation';

const DEFAULT_EVENTS = 'init load page:load turbolinks:load nested:fieldAdded';

widgets('live-validation', 'input, select, textarea', merge({
  on: DEFAULT_EVENTS,
  events: 'input change blur',
  inputBuffer: 500,
}, VALIDATION_OPTIONS));
widgets('form-validation', 'form', merge({
  on: DEFAULT_EVENTS,
}, VALIDATION_OPTIONS));

document.addEventListener('DOMContentLoaded', (e) => {
  FastClick.attach(document.body);
});
